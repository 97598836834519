//https://codepen.io/eehayman/pen/qdGZJr

import { Injectable } from '@angular/core';

import * as $ from 'jquery';
import { GlobalVariablesService } from './global.service';
import { DirectionEnum } from '../enums/direction.enum';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class Parallax {

  onScroll(direction, canScrollUp: boolean, canScrollDown: boolean) {
    //var dir = direction == DirectionEnum.Up ? 'UP' : 'DOWN';
    //console.log('direction-> ' + dir + ' | canScrollDown-> ' + canScrollDown + ' | canScrollUp-> ' + canScrollUp);
    if (this.ticking != true) {
      if (canScrollDown && direction == DirectionEnum.Down
        //  || this.currentSlideNumber == 3 && direction == DirectionEnum.Up
      ) {
        //console.log('Up scroll');
        //Down scroll
        this.ticking = true;
        this.slideDown(this.variablesService.currentView + 1);
        this.slideDurationTimeout(this.slideDurationSetting);
      }
      if (canScrollUp && direction == DirectionEnum.Up
        //  || this.currentSlideNumber == 2 && event.deltaY <= -this.scrollSensitivitySetting
        || this.variablesService.currentView == 0 && direction == DirectionEnum.Up
      ) {
        //console.log('Up scroll');
        //Up scroll
        this.ticking = true;
        this.slideUp(this.variablesService.currentView - 1);
        this.slideDurationTimeout(this.slideDurationSetting);
      }
    }
  }




  private ticking: boolean = false;
  public scrollSensitivitySetting: number = 100; //Increase/decrease this number to change sensitivity to trackpad gestures (up = less sensitive; down = more sensitive)
  private slideDurationSetting: number = 1600; //Amount of time for which slide is "locked"
  private totalSlideNumber: number = 3;//$(".background").length;


  constructor(
    private variablesService: GlobalVariablesService,
    private _router: Router,
  ) {
  }

  resetViewNumber() {
    this.variablesService.setCurrentView(0);
  }


  slideDown(newPos: number) {
    this.variablesService.setGoingDown(true);
    this.variablesService.setUrl(newPos);

    // will scroll to 4th h3 element



    //console.log('SLIDE DOWN');
    if (this.variablesService.currentView !== this.totalSlideNumber - 1) {
      this.variablesService.setCurrentView(this.variablesService.currentView + 1);
      //  this.nextItem();
    }



    if (newPos == this.variablesService.currentView && this.variablesService.currentView == 1) {
      $('.view_container').scrollTop(0);
      //console.log('scroll');
    }


  }
  slideUp(newPos: number) {
    this.variablesService.setGoingDown(false);
    this.variablesService.setUrl(newPos);

    //console.log('SLIDE UP');
    if (this.variablesService.currentView !== 0) {
      this.variablesService.setCurrentView(this.variablesService.currentView - 1);
      //  this.previousItem();
    }

    if (newPos == this.variablesService.currentView && this.variablesService.currentView == 1) {
      $('.view_container').scrollTop(window.innerHeight);
      //console.log('scrollBaix');
    }

  }



  slideView(newPos: number) {
    var _oldViewPos: number = this.variablesService.currentView == undefined ? 0 : this.variablesService.currentView;
    if (newPos > this.variablesService.currentView) {
      this.variablesService.ifMenuOpenClose();
      for (var i = this.variablesService.currentView; i < newPos; i++) {
        this.slideDown(newPos);
      }
    } else if (newPos < this.variablesService.currentView) {
      this.variablesService.ifMenuOpenClose();

      for (var i = this.variablesService.currentView; i > newPos; i--) {
        this.slideUp(newPos);
      }
    }





  }


  isProjectView(newView: number) {

    this.variablesService.setUrl(newView);


    this.variablesService.setCanChangeParallaxScreenUp(false);
    this.variablesService.setCanChangeParallaxScreenDown(false);
    if (this.variablesService.areInProjectView == true) {
      this._router.navigateByUrl('/');
      this.variablesService.setCurrentView(newView);
      this.variablesService.ifMenuOpenClose();
    }

    if (newView > 0) {




      this.slideView(2);
      setTimeout(() => {
        document.querySelector(newView == 1 ? '#projects' : newView == 2 ? '#construction' : newView == 3 ? '#social' : '#contact-form').scrollIntoView({ block: 'start' });
      }, this.variablesService.loadServicesView == true ? 200 : 550);
    } else {
      this.slideView(newView);


    }
    this.doShowView(newView > 1 ? 2 : newView);



  }




  slideDurationTimeout(slideDuration) {
    this.doShowView(this.variablesService.currentView);
    setTimeout(() => {
      this.ticking = false;
    }, slideDuration);
  }

  /*
    swipe(isDown: boolean) {
      if (isDown) {
        this.slideUp();
      } else {
        this.slideDown();
      }
    }
  */
  /*
    nextItem() {
      //TODO: Mira açò, he fet aixi , ja que si al entrar anam a Servicios sense passar per Porjectes no podeiem anar a Contact
      if (this.variablesService.currentView == 3 && this.variablesService.loadProjectsView != true) {
        var $previousSlide1 = $(".background").eq(1);
        var $previousSlide3 = $(".background").eq(3);
        $previousSlide1.removeClass("up-scroll").addClass("down-scroll");
        $previousSlide3.removeClass("up-scroll").addClass("down-scroll");
      } else {
        //AQUI
        this.variablesService.ifMenuOpenClose();
        var $previousSlide = $(".background").eq(this.variablesService.currentView - 1);
        $previousSlide.removeClass("up-scroll").addClass("down-scroll");

      }
    }

    previousItem() {
      if (this.variablesService.currentView == 2 && this.variablesService.loadProjectsView != true) {
        var $previousSlide1 = $(".background").eq(1);
        var $previousSlide3 = $(".background").eq(3);
        $previousSlide1.removeClass("down-scroll").addClass("up-scroll");
        $previousSlide3.removeClass("down-scroll").addClass("up-scroll");
      } else {
        this.variablesService.ifMenuOpenClose();
        var $currentSlide = $(".background").eq(this.variablesService.currentView);
        $currentSlide.removeClass("down-scroll").addClass("up-scroll");
      }
    }
  */


  doShowView(newView: number) {
    if (newView == 1) {
      this.variablesService.setLoadProjectsViewTrue();
      this.variablesService.setCanChangeParallaxScreenUp(true);
    } else if (newView > 1) {
      this.variablesService.setLoadServicesViewTrue();
      this.variablesService.setCanChangeParallaxScreenUp(true);
    }
    /* else if (newView == 3) {
      this.variablesService.setLoadContactViewTrue();
      this.variablesService.setCanChangeParallaxScreenUp(true);
    }
    */
  }



}
