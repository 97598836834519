import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { Router, UrlTree, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from 'src/app/services/global.service';
import { Parallax } from 'src/app/services/parallax.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit, OnDestroy, AfterViewInit {


  public innerWidth: any;
  public resized: boolean;
  public lastId: number;
  public lastIdWidth: number;
  public lastZoomed: number;
  public projectId: number;
  public title_es: string;
  public title_ct: string;
  public title_en: string;
  public title_de: string;
  //public text: string;
  public subtitle_es: string;
  public subtitle_ct: string;
  public subtitle_en: string;
  public subtitle_de: string;
  public masonryItems = [{ src: '', width: 0 }];
  // get reference
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 0, initLayout: true, columnWidth: 30
  };



  constructor(
    private _router: Router,
    public variablesService: GlobalVariablesService,
    public parallax: Parallax,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.checkMainParams();
    var timer = setInterval(() => {
      if (this.variablesService.projectData != undefined && this.variablesService.projectData.length > 0) {
        //Esperamos a tener la data del Slider cargada en this.variablesService.slideShowItems antes de inicilizar slider
        let project = this.variablesService.projectData.find(item => item.Id == this.projectId);
        this.title_es = project.Title_es;
        this.title_ct = project.Title_ct;
        this.title_en = project.Title_en;
        this.title_de = project.Title_de;
        //this.text = project.Text;
        this.subtitle_es = project.Subtitle_es;
        this.subtitle_ct = project.Subtitle_ct;
        this.subtitle_en = project.Subtitle_en;
        this.subtitle_de = project.Subtitle_de;
        this.createMasonryItems(project);
        clearInterval(timer);
      }
    });
  }

  ngOnDestroy(): void {
  }
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.variablesService.setAreInProjectView(true);
    this.variablesService.setLoadProjectViewTrue();
  }
  checkMainParams() {
    this.route.params.subscribe((params) => {
      if (Object.keys(params).length == 0) {
        //   this.is_params = false;
      } else {
        // this.is_params = true;
        if (params.id != null) {
          //console.log(params.id);
          this.projectId = +params.id;
        }
      }
    });
  }

  ngAfterViewInit() {

    //https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change
    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  onResize(event) {
    if (this.variablesService.deviceType == 0 && this.resized != true) {
      this.resized = true;
      this.rellocate();
    }
  }

  calculateWidth() {
    this.innerWidth = window.innerWidth;
  }

  createMasonryItems(project: any) {
    //FER RANDOM



    for (let i = 0; i < project.Fotos; i++) {
      this.masonryItems.push({ src: './assets/proyectos/' + project.Id + '/' + i + '.jpg', width: this.variablesService.getRandomWidth() })
    }
    this.masonryItems = this.variablesService.shuffleArray(this.masonryItems);
  }



  enlargeImage(id: number) {
    //console.log(id);
    if (this.lastId != null) {
      this.masonryItems[this.lastId].width = this.lastIdWidth;
    }
    this.lastId = id;
    this.lastIdWidth = this.masonryItems[id].width;

    this.masonryItems[id].width = 2;
    this.rellocate();
    this.setLastZoomed(id);
  }
  setLastZoomed(index: number) {
    this.lastZoomed = index;
  }


  rellocate() {
    // after the order of items has changed
    //this.masonry.reloadItems();
    this.masonry.layout();
    setTimeout(() => {
      this.resized = false;
      this.calculateWidth();
      this.rellocate();
    }, 1500);
  }

  navigate(url: string | UrlTree) {
    this._router.navigateByUrl(url);
  }

}
