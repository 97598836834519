import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren } from '@angular/core';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { Router, UrlTree, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from 'src/app/services/global.service';
import { Parallax } from 'src/app/services/parallax.service';
import { InViewportDirective } from 'ng-in-viewport';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit, AfterViewInit {
  @ViewChildren(InViewportDirective)


  public innerWidth: any;
  public resized: boolean;
  public lastId: number;
  public lastIdWidth: number;
  private projects_flag: boolean;
  private showFirstPair: boolean;
  private showFirstUnpair: boolean;
  public delayTime: number = 10;
  public citas_es = [{ text: '“Un gran trato. Los trabajos muy buenos. Próximamente volveremos a trabajar juntos. Gracias por todo.”', name: 'M.E. - Cliente' },
  { text: '“Obra prácticamente ejecutada con encomiable interés mostrado por parte de los responsables de la empresa.”', name: 'P.C. – Arquitecto Técnico' },
  { text: '“Responden correctamente a las instrucciones dadas en obra. Las obras se realizan a buen ritmo. Los precios son correctos. Los acabados de buena calidad.”', name: 'T.G.Z Arquitecto' },
  { text: '“Excelente relación calidad precio. Buen servicio y buen trato. Ejecución de los trabajos en plazo y sin costes añadidos excepcionales.” ', name: 'C.B. – Cliente' },
  { text: '“Se han adaptado al presupuesto, la obra ha ido a buen ritmo y los acabados son excelentes. Sin duda, una gran elección. Nuestra próxima obra será con la misma constructora.”', name: 'J.B. – Cliente' },
  ];
  public citas_ct = [{ text: '“Un gran tracte. Els treballs molt bons. Properament tornarem a treballar junts. Gràcies per tot.”', name: 'M.E. - Client' },
  { text: '“Obra pràcticament executada amb encomiable interès mostrat per part dels responsables de l\'empresa.”', name: 'P.C. – Arquitecte Tècnic' },
  { text: '“Responen correctament a les instruccions donades en obra. Les obres es realitzen a bon ritme. Els preus són correctes. Els acabats de bona qualitat.”', name: 'T.G.Z Arquitecte' },
  { text: '“Excelente relación calidad precio. Buen servicio y buen trato. Ejecución de los trabajos en plazo y sin costes añadidos excepcionales.” ', name: 'C.B. – Client' },
  { text: '“S\'han adaptat a el pressupost, l\'obra ha anat a bon ritme i els acabats són excel·lents. Sens dubte, una gran elecció. La nostra pròxima obra serà amb la mateixa constructora.”', name: 'J.B. – Client' },
  ];
  public citas_en = [{ text: '“A great deal. Very good works. We will work together again soon. Thanks for everything.”', name: 'M.E. - Client' },
  { text: '“Work practically executed with commendable interest shown by the managers of the company.”', name: 'P.C. – Technical Architect' },
  { text: '“They respond correctly to the instructions given on site. The works are being carried out at a good pace. The prices are correct. Good quality finishes.”', name: 'T.G.Z Architect' },
  { text: '“Great value for the price. Good service and good treatment. Execution of work on time and without exceptional added costs.” ', name: 'C.B. – Client' },
  { text: '“They have adapted to the budget, the work has gone at a good pace and the finishes are excellent. Definitely a great choice. Our next work will be with the same construction company.”', name: 'J.B. – Client' },
  ];
  public citas_de = [{ text: '“Viel. Sehr gute Werke. Wir werden bald wieder zusammenarbeiten. Danke für alles.”', name: 'M.E. - Klient' },
  { text: '“Praktisch ausgeführte Arbeiten mit lobenswertem Interesse der Manager des Unternehmens.”', name: 'P.C. – Technischer Architekt' },
  { text: '“Sie reagieren korrekt auf die Anweisungen vor Ort. Die Arbeiten werden in gutem Tempo durchgeführt. Die Preise sind korrekt. Gute Qualität.”', name: 'T.G.Z Architekt' },
  { text: '“Tolles Preis-Leistungs-Verhältnis. Guter Service und gute Behandlung. Pünktliche Ausführung der Arbeiten ohne außergewöhnliche Zusatzkosten.” ', name: 'C.B. – Klient' },
  { text: '“Sie haben sich an das Budget angepasst, die Arbeit ist in einem guten Tempo verlaufen und die Oberflächen sind ausgezeichnet. Auf jeden Fall eine gute Wahl. Unsere nächste Arbeit wird bei derselben Baufirma sein.”', name: 'J.B. – Klient' },
  ];

  public masonryItems = [];

  slideProjects = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='space_next_home'></div>",
    "prevArrow": "<div class='space_prev_home'></div>",
    "dots": true,
    "infinite": false, "autoplay": true,
    "autoplaySpeed": this.variablesService.carrousselSpeed
  };





  // get reference
  @ViewChild(NgxMasonryComponent, { static: true }) masonry: NgxMasonryComponent;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 0, initLayout: true, columnWidth: 30
  };



  constructor(
    private _router: Router,
    public variablesService: GlobalVariablesService,
    public parallax: Parallax,
    public translate: TranslateService
  ) {
    var timer = setInterval(() => {
      if (this.variablesService.projectData != undefined && this.variablesService.projectData.length > 0) {
        //Esperamos a tener la data del Slider cargada en this.variablesService.slideShowItems antes de inicilizar slider
        this.createMasonryItems();
        clearInterval(timer);
      }
    });
    this.variablesService.setLoadProjectsViewTrue();
    this.calculateWidth();


  }

  ngOnInit() {
    //this.variablesService.setDoShowArrowDown(false);

  }
  createMasonryItems() {
    //FER RANDOM

    let tempMasonry = [];

    for (let i = 0; i < this.variablesService.projectData.length; i++) {
      console.log(i);

      let randomFoto0: number = this.randomInteger(0, this.variablesService.projectData[i].Fotos - 1);
      let randomFoto1: number = this.randomInteger(0, this.variablesService.projectData[i].Fotos - 1);

      tempMasonry.push(
        {
          src: ['./assets/proyectos/' + this.variablesService.projectData[i].Id + '/' + randomFoto0 + '.jpg', './assets/proyectos/' + this.variablesService.projectData[i].Id + '/' + randomFoto1 + '.jpg'], title_es: this.variablesService.projectData[i].Title_es, title_ct: this.variablesService.projectData[i].Title_ct, title_en: this.variablesService.projectData[i].Title_en, title_de: this.variablesService.projectData[i].Title_de, location: this.variablesService.projectData[i].Location, id: this.variablesService.projectData[i].Id, width: this.variablesService.getRandomWidth()
        },
      )

    }





    tempMasonry = this.variablesService.shuffleArray(tempMasonry);
    //Title & text
    tempMasonry.splice(0, 0, { src: [], title_es: '', title_ct: '', title_en: '', title_de: '', location: '', id: 0, width: 0 });
    //sum 1
    tempMasonry.splice(2, 0, { src: [], title_es: '', title_ct: '', title_en: '', title_de: '', location: '', id: 0, width: 0 });
    //sum 2
    tempMasonry.splice(5, 0, { src: [], title_es: '', title_ct: '', title_en: '', title_de: '', location: '', id: 0, width: 0 });

    this.masonryItems = tempMasonry;

    console.log(this.masonryItems);

  }
  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngAfterViewInit() {
    this.projects_flag = true;
    //this.onTimeOutImage();
    this.rellocate();


    //this.onTimeOutImage();
    setTimeout(() => {
      this.variablesService.setDoShowArrowDown(true);
      this.variablesService.setCanChangeParallaxScreenUp(true);

      this.variablesService.isProjectsVisible(true);

    }, 2500);
  }

  ngOnDestroy() {
    this.projects_flag = false;
  }
  onResize(event) {
    if (this.resized != true) {
      this.resized = true;
      //this.rellocate();
    }

  }

  calculateWidth() {
    //setTimeout(() => {
    this.innerWidth = window.innerWidth;
    //  }, 200);
  }

  navigateProject(id: number) {
    //  this._router.navigateByUrl('/proyecto/' + id);
    this._router.navigateByUrl('/proyecto/' + id);
  }


  rellocate() {

    // after the order of items has changed
    this.masonry.reloadItems();
    this.masonry.layout();
    setTimeout(() => {
      this.resized = false;
      this.calculateWidth();
      this.rellocate();
    }, 350);
  }



  onTimeOutImage() {
    /*TODO:
    MIRA QUE CADA VEGADA SIGUI DIFERENT
    */
    if (this.showFirstPair == true && this.showFirstUnpair != true) {
      if (this.showFirstUnpair == undefined) {
        this.showFirstUnpair = false;
      } else {
        this.showFirstUnpair = !this.showFirstUnpair;
      }
      console.log('this.showFirstUnpair');
    } else {
      if (this.showFirstPair == undefined) {
        this.showFirstPair = false;
      } else {
        this.showFirstPair = !this.showFirstPair;
      }
      console.log('this.showFirstPair');

    }
    this.rellocate();

    /*
        console.log('****');
        console.log(this.showFirst);
        console.log('****');
    */
    setTimeout(() => {
      if (this.projects_flag) {
        this.onTimeOutImage();
      }
    }, this.delayTime * 1000);
  }

  navigate(url: string | UrlTree) {
    this._router.navigateByUrl(url);
  }



}
