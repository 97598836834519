import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { GlobalVariablesService } from 'src/app/services/global.service';
import { Parallax } from 'src/app/services/parallax.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, AfterViewInit {



  constructor(
    private _router: Router,
    public variablesService: GlobalVariablesService,
    private parallax: Parallax,
  ) {

    this.variablesService.setLoadProjectsViewTrue();

  }

  ngOnInit() {
  }


  ngAfterViewInit() {

  }
  navigate(url: string | UrlTree) {
    this._router.navigateByUrl(url);
  }



}
