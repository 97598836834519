import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Parallax } from './../../services/parallax.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from 'src/app/services/global.service';
import { ProjectsComponent } from 'src/app/components/projects/projects.component';
import { ServicesComponent } from '../services/services.component';
import { ContactComponent } from '../contact/contact.component';
import { ViewPortMobileHeight } from 'src/app/services/viewport_mobile.service';
import { DirectionEnum } from 'src/app/enums/direction.enum';
import { CarrusselPhotosService } from 'src/app/services/carrussel_photos.service';
//import { CarrusselModel } from 'src/app/models/carrussel.model';
import { TranslateService } from '@ngx-translate/core';

//var simpleslider = require('simple-slider');

declare var require: any


//https://stackoverflow.com/questions/58473921/why-cant-i-use-touchevent-in-safari
//eferenceError: Can't find variable: TouchEvent
export type AppTouchEvent = TouchEvent;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './css/parallax_portada.css'], providers: [
    ProjectsComponent, ServicesComponent, ContactComponent, Parallax
  ]
})
export class HomeComponent implements OnInit, AfterViewInit {
  //https://github.com/angular/angular/issues/10834
  //Mobile touch event down
  /*

    @HostListener('document:touchmove', ['$event'])
    onTouchMove($event: AppTouchEvent): void {
      console.log('this.variablesService.canChangeParallaxScreenUp-> ' + this.variablesService.canChangeParallaxScreenUp);

      const clientY = $event.changedTouches[0].clientY;
      //console.log($event.changedTouches[1]);
      const movementY = clientY - this._touchLastY;
      this._touchLastY = clientY;
      console.log(movementY);
      if (movementY < 0 && this.parallax.getCurrentSlideNumber() == 0) {
        console.log('ViewNumber ->' + this.parallax.getCurrentSlideNumber());

        this.parallaxDirection(DirectionEnum.Down)
      } else if (this.variablesService.canChangeParallaxScreenUp == true && movementY > 0 && this.parallax.getCurrentSlideNumber() != 0) {
        console.log('34');
        this.parallaxDirection(DirectionEnum.Up)
      }
};

  */

/*
  @HostListener('window:wheel', ['$event']) // for window scroll events
  onScroll(event) {

    if (event.deltaY >= this.parallax.scrollSensitivitySetting) {
      //this.parallaxDirection(DirectionEnum.Down);
      this.variablesService.setCurrentView(1);
      this.variablesService.setLoadProjectsViewTrue();

    } else if (event.deltaY <= -this.parallax.scrollSensitivitySetting && this.variablesService.currentView == 1) {
      this.parallaxDirection(DirectionEnum.Up);

    }

  }

*/

  parallaxDirection(direction) {
    //this.parallax.onScroll(direction, this.variablesService.canChangeParallaxScreenUp, this.variablesService.canChangeParallaxScreenDown);

  }
  slideHome = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div id='buto_next' class='space_next_home'></div>",
    "prevArrow": "<div class='space_prev_home'></div>",
    "dots": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": this.variablesService.carrousselSpeed,
    "arrows": this.variablesService.deviceType == 0 ? true : false
      
  };



  private flag: boolean;
  public sliderPos: number = 0;
  public delayTime: number = 6;


  defaultImage: string[];
  images: string[];


  constructor(
    public parallax: Parallax,
    public carrusellPhotos: CarrusselPhotosService,
    public variablesService: GlobalVariablesService,
    private _router: Router,
    private _viewPortHeight: ViewPortMobileHeight,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {


  }

  ngOnInit() {


    //this.flag = true;
    //this.onTimeOut();




    this.route.params.subscribe((params) => {
      if (Object.keys(params).length == 0) {
        //   this.is_params = false;
      } else {
        switch (params.featured) {
          case 'proyectos':
            this.parallax.isProjectView(1);
            break;

          case 'servicios':
            this.parallax.isProjectView(2);
            break;

          case 'redes':
            this.parallax.isProjectView(3);
            break;

          case 'contacto':
            this.parallax.isProjectView(4);
            break;
          default:
        }
      }
    });
  }


  ngAfterViewInit() {



    this.variablesService.hideMenuMobile();

    //Esperam que la interfaceaixi carregat per caprura el next_arrow i sumar un item
/*
    const interval = setInterval(() => {
        const button = $('.space_next_home')
        // if button is ready
        if (button) {
          // add click handlers
          button.on('click', (evt) => {
            //const target = evt.target
            this.sliderChange();
            $('#buto_next').removeClass('space_next_home');
            setTimeout(function(){
              $('#buto_next').addClass('space_next_home');
            },1200);
          });
          // stop polling
          clearInterval(interval)
        }
      }, 1200)
*/
    this.onResize();
    /*
        var timer = setTimeout(() => {
          if (this.variablesService.slideShowItems != undefined && this.variablesService.slideShowItems.length > 0) {
            //Esperamos a tener la data del Slider cargada en this.variablesService.slideShowItems antes de inicilizar slider
            simpleslider.getSlider({
              container: document.getElementById('myslider'),
              delay: this.delayTime,
            });
            clearInterval(timer);
          }
        }, 1000);

    */
    /*
        setTimeout(() => {
          this.variablesService.setLoadProjectsViewTrue();

        }, 2000);
    */

  }


  onResize() {
    //If is mobile whe get the Viewportheight to show the home screen
    if (this.variablesService.deviceType == 1) {
      this._viewPortHeight.setCssHeight();
    }
  }

  ngOnDestroy() {
    this.flag = false;
  }
  clickProject(id: number) {

    this._router.navigateByUrl('/proyecto/' + id);
  }

  sliderChange() {
    if (this.sliderPos < this.variablesService.slideShowItems.length - 1) {
      this.sliderPos++;
    } else {
      this.sliderPos = 0;
    }
    //console.log(this.variablesService.slideShowItems[this.sliderPos].Title_es);
  }

/*
  onTimeOut() {
    setTimeout(() => {
      if (this.flag) {
        this.sliderChange();
        this.onTimeOut();
      }
    }, this.delayTime * 1000);
  }
  */


}
