import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectComponent } from './components/project/project.component';
import { HomeComponent } from './components/home/home.component';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProjectsComponent } from './components/projects/projects.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  /*{
    path: ':featured', component: HomeComponent
  },
  */
  {
    path: 'proyecto/:id', component: ProjectComponent
  },
/*
  {
    //Condiciones de servicio, politica de privacidad y coockies
    path: 'politica-de-cookies', component: ProjectComponent
  },
  */
  {
    path: '**', component: HomeComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
