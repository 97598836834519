import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectData } from '../models/json_data.model';


@Injectable()
export class PeticionesService {
  public url: string;

  constructor(
    private _http: HttpClient
  ) {
  }




  getlastInstagramPost(): Observable<any> {
    var token = 'IGQVJVeUV2MnNrR0hJM1RZAN3ZAXdVFtQjBad21US3NHenNHZAnpxV1BadzQtVVJEU1lvOGRKRTF1c1ZAlSUVQNmdjS21JSEhGb1lyaS1uSG4wMlpCV1FHTzZAIV0laTi1JXzRweWh1cFZAn';
    const url = 'https://graph.instagram.com/me/media?fields=media_url,thumbnail_url, caption, permalink&access_token=' + token + '&limit=8';
    return this._http.jsonp(url, 'callback');
  }




  getlastFacebookPost(): Observable<any> {
    var page_id = '260523601098201';
    var token = 'EAAluLIqwDq8BABrSp3eVzTcBcHe49yKIAIokT1ZAfe6XEJMrMap5UcjQIJrgeyAkUDKInMT9MTSMrqT1236VxZBVnrUa7faDBoFgZCwepDJf7WIMBMRgy7LeEC4fZBLtZCfdlFjbgt0TEGMphvLSdPhZBOhwlTsY9Qtj8sE2r0pBb0nHHdSM5SGGHVK5ZBcwtDtmZALzXJMg6wZDZD';
    const url = 'https://graph.facebook.com/' + page_id + '/feed?fields=message,full_picture,permalink_url&limit=6&access_token=' + token;//+'&limit=2'
    return this._http.jsonp(url, 'callback');
  }

  getJsonData(): Observable<any> {

    return this._http.get("assets/help_files.json");



  }

  /*
    getJssonData(): Observable<ProjectData[]> {
      let params = new HttpParams();
      console.log(this._http.get("assets/help_files.json").pipe(map(res => res as ProjectData[] || [])));
      // in case that the property results in the res POJO doesnt exist (res.results returns null) then return empty array ([])
    }

    //  me?/feed?fields=posts{message,full_picture,permalink_url}&limit=3



    /*

    getlastFacebookPost(page_id, secret): Observable<any>{
      let peticio = {
        "appId" : page_id,
        "secret" : secret
      };

      let params =  "q=" + JSON.stringify(peticio);
      let headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");


      return this._http.post("https://eastmallorcacup.com/assets/Facebook/Facebook.php?", params, {headers: headers});


    }
    */





}
