import { Injectable } from '@angular/core';
import { CarrusselModel } from '../models/carrussel.model';
import { ProjectData } from '../models/json_data.model';


@Injectable()
export class CarrusselPhotosService {
  private carrusselNum = 3;

  constructor(
  ) { }

  createCarrussel(data: ProjectData[], isComputer: boolean): CarrusselModel[] {
    let tempCarrussel: CarrusselModel[] = [];

    let arrayPos: number = -1;
    data.forEach(project => {
      arrayPos++;
      if (project.Promotionable == true) {
        let randomFoto: number = this.randomInteger(0, project.Portada.length - 1);
        if (isComputer && project.Portada[randomFoto].Horizontal == false) {
          //Si estamos en ordenador y la foto es vertical elegimos la siguiente (nunca poner dos verticales seguidas, ni que la vertical sea la última)
          randomFoto++;
        }
        tempCarrussel.push(
          { Small: './assets/proyectos/' + project.Id + '_small/' + project.Portada[randomFoto].Foto + '.jpg', Big: './assets/proyectos/' + project.Id + '/' + project.Portada[randomFoto].Foto + '.jpg', Title_es: project.Title_es, Title_ct: project.Title_ct, Title_en: project.Title_en,Title_de: project.Title_de,  Subtitle_es: project.Subtitle_es,  Subtitle_ct: project.Subtitle_ct,  Subtitle_en: project.Subtitle_en,  Subtitle_de: project.Subtitle_de, Id: project.Id },
        );
        if (tempCarrussel.length == this.carrusselNum - 1) {
          return true;
        }

      }
    });

    return tempCarrussel;
  }


  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
