import { Pipe, PipeTransform } from '@angular/core';

import "../../assets/js/clamp.min.js";

declare var require: any
var $ = require('jquery');

declare var $clamp: any;

@Pipe({
  name: 'reform_text',
  //https://stackoverflow.com/questions/36624914/trigger-pipe-reload-without-change-in-input
  //pure: false
})
export class ReformTextPipe implements PipeTransform {
  transform(tit_class: any, txt_class: any, img_class?: any, ): any {
    setTimeout(() => {
      var img = document.getElementsByClassName(img_class);
      var tit = document.getElementsByClassName(tit_class);
      var txt = document.getElementsByClassName(txt_class);
      //console.log(img[0].children.length);

      $clamp(txt[0], { clamp: 6 });


      if (this.countLines(txt[0]) == 0) {
        if (img[0].children.length == 0) {
          //Si no tiene imagen dejamos que el texto sea más largo
          $clamp(tit[0], { clamp: 10 });
        } else {
          $clamp(tit[0], { clamp: 6 });
        }
      } else {
        $clamp(tit[0], { clamp: 3 });

      }

      //if(tit[0] != undefined){//Fallava al tornar amb back
      //  if(this.countLines(tit[0]) <= 1){
      //    $clamp(txt[0], {clamp:6});
      //  } else

      if (this.countLines(tit[0]) == 2) {
        $clamp(txt[0], { clamp: 4 });
      } else if (this.countLines(tit[0]) >= 3) {
        $clamp(txt[0], { clamp: 3 });
      }
    }, 150);

  }
  //}




  countLines(target) {
    var style = window.getComputedStyle(target, null);
    var height = parseInt(style.getPropertyValue("height"));
    var font_size = parseInt(style.getPropertyValue("font-size"));
    var line_height = parseInt(style.getPropertyValue("line-height"));

    if (isNaN(line_height)) line_height = font_size * 1.2;


    var lines = Math.ceil(height / line_height);
    //console.log("Lines: " + lines);
    return lines;

  }


}
