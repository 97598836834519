import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private mailApi = 'https://mailthis.to/codeninja'


  public nombre: string;
  public mail: string;
  public texto: string;
  submitted = false;

  endpoint : string;
  http : HttpClient;

  ngOnInit() {
       //Start php via the built in server: $ php -S localhost:8000
       this.endpoint = "https://eastmallorcacup.com/assets/sendEmail.php";
  }

  constructor(
    private translate: TranslateService,
    http : HttpClient,
    private _router: Router,
  ) {  this.http = http;


    }





  PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' })
      .pipe(
        map(
          (response) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }



    onSubmit() {
      this.submitted = true;

      let postVars = {
        email : this.mail,
        name : this.nombre,
        asunto : 'Mensaje Web',
        /////
        message :
`Nombre:  ${this.nombre}
Correo: ${this.mail}
Texto: ${this.texto}
`
        };



      //You may also want to check the response. But again, let's keep it simple.
      this.http.post(this.endpoint, postVars)
          .subscribe(
              response => {

                  alert(this.translate.instant('contact.send_ok_tit')+' '+this.translate.instant('contact.send_ok_txt'));

                }

          )



    }

}
