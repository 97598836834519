import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { GlobalVariablesService } from 'src/app/services/global.service';
import { Parallax } from 'src/app/services/parallax.service';
import { Social } from '../../models/social.model';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit, AfterViewInit {
  public social_list: Social[];
  public call_reform_pipe = false;
  public can_send = true;




  public is_contact:boolean; //Si true es caontacto si false es inscripción

  submitted = false;
  endpoint : string;
  http : HttpClient;













  registerForm: FormGroup;

  slideSocial = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    "nextArrow": "<div class='space_next_home gallery_controll_contact'></div>",
    "prevArrow": "<div class='space_prev_home gallery_controll_contact'></div>",
    "dots": true,
    "infinite": false,
    'responsive': [{
      'breakpoint': 400,
      'settings': {
        'slidesToShow': 2,
        'slidesToScroll': 2,
        'initialSlide': 1,
        "arrows": this.variablesService.deviceType == 0 ? true : false,
        "dots": false,
      }
    },
    {
      'breakpoint': 624,
      'settings': {
        'slidesToShow': 3,
        'slidesToScroll': 3,
        'initialSlide': 1,
        "arrows": this.variablesService.deviceType == 0 ? true : false,
        "dots": false,
      }
    }]

  };


  constructor(
    private builder: FormBuilder,
    http : HttpClient,
    private _router: Router,
    public variablesService: GlobalVariablesService,
    private parallax: Parallax,
    private contact: ContactService,
    private translate: TranslateService

  ) {
    this.variablesService.setDeviceType();
    this.variablesService.setLoadProjectsViewTrue();
this.http = http;
  }

  ngOnInit() {
    this.endpoint = "https://joandesalort.es/assets/sendEmail.php";

    if (!this.variablesService.is_social_ready()) {
      this.variablesService.getSocial();
    }
    this.registerForm = this.builder.group({
      Fullname: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Comment: new FormControl('', [Validators.required]),
      CheckBox: new FormControl(null, [Validators.required])
    });


    this.registerForm.valueChanges.subscribe((value: any) => {


        console.log(this.registerForm.controls.Email.errors);

      if (this.registerForm.get('CheckBox').value != true) {


        this.can_send = false;
        //this.registerForm.get('inputField').disable()

      } else {
        this.can_send = true;

      }
    });
  }

  ngAfterViewInit() {

    //O pasas a AfterView perque pugui fer el reform text  bé
    //Esperamos hasta que esté disponible toda la info social para seguir
    //this.variablesService.getUser();
    var timer = setInterval(() => {
      if (this.variablesService.is_social_ready()) {
        clearInterval(timer);// Stops or does the work
        const promise =
          new Promise((resolve, reject) => {
            // do some async stuff
            if (this.variablesService.is_social_ready()) resolve();
            else reject();
          })
            .then(
              (data) => {




                clearInterval(timer);
                //Ahora podemos seguir



                /*
                              console.log(this.variablesService.social_facebook);
                              //console.log(this.variablesService.social_twitter);
                              console.log(this.variablesService.social_instagram);
                              let temp_list: Social[] = [];
                              for (let i = 0; i < 7; i++) {
                                if (i == 0 && this.variablesService.social_instagram[0] != null) { temp_list.push(this.variablesService.social_instagram[0]); }
                                else



                                  if (i == 2 && this.variablesService.social_facebook[0] != null) { temp_list.push(this.variablesService.social_facebook[0]); }
                                  else if (i == 3 && this.variablesService.social_instagram[1] != null) { temp_list.push(this.variablesService.social_instagram[1]); }
                                  else if (i == 4 && this.variablesService.social_facebook[1] != null) { temp_list.push(this.variablesService.social_facebook[1]); }
                                  else if (i == 5 && this.variablesService.social_instagram[2] != null) { temp_list.push(this.variablesService.social_instagram[2]); }
                                  else if (i == 6 && this.variablesService.social_facebook[2] != null) { temp_list.push(this.variablesService.social_facebook[2]); }

                                //else if (i == 7 && this.variablesService.social_facebook[3] != null) { temp_list.push(this.variablesService.social_facebook[3]); }

                                if (i == 6) {

                                  this.social_list = temp_list;
                                }
                              }
                              */
                //  this.social_list = this.variablesService.social_instagram;







              },
              (err) => {
              }


            );


      }
    });



  }

/*
  onSubmit(FormData) {
    //console.log(FormData['Email']);
    this.contact.PostMessage(FormData)
      .subscribe(response => {
        location.href = 'https://mailthis.to/confirm'
        console.log(response)
      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
  }

*/
  navigate(url: string | UrlTree) {
    this._router.navigateByUrl(url);
  }

  reformTxt() {
    this.variablesService.callReformText();
  }

  onResize() {
    this.variablesService.callReformText();
  }
  slickInit(e) {
    //console.log('slick initialized');
  }




    onSubmit(FormData: { [x: string]: any; }) {
      this.submitted = true;

      let postVars = {
        email :  FormData['Email'],
        name : FormData['Fullname'],
        asunto : "MENSAJE WEB JOAN DE SALORT",
        /////
        message :
`Nombre:  ${FormData['Fullname']}

Correo: ${FormData['Email']}

Texto: ${FormData['Comment']}
`
        };



      //You may also want to check the response. But again, let's keep it simple.
      this.http.post(this.endpoint, postVars)
          .subscribe(
              response => {
            //   console.log(response);

                if(response=="200"){//response.status == 200

                  alert(this.translate.instant('contact.send_ok_tit')+' '+this.translate.instant('contact.send_ok_txt'));
                  //para que al volver a productos el infomessage se esconda con anim

                } else {
                  alert(this.translate.instant('contact.send_error_tit')+' '+this.translate.instant('contact.send_error_txt'));
                  //para que al volver a productos el infomessage se esconda con anim

                }
              }
          )



    }

}
