import { Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[lastElement]'
})
export class LastElementDirective {
  @Input() lastElement: any;
  @Output() lastFunc = new EventEmitter();
  constructor(private el: ElementRef) {
    this.setTimer(); //somehow lastElem value is available on next tick
  }
  setTimer() {
    setTimeout(() => {
      if (this.lastElement) this.lastFunc.emit();
    }, 0);
  }
}


//Directiva per sabre quan el template arriba al final del array
