import { Injectable, isDevMode } from '@angular/core';
import { DeviceType } from '../enums/device_type.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PeticionesService } from './peticiones.service';
import { finalize } from 'rxjs/operators';
import { Social } from '../models/social.model';
import { ProjectData } from '../models/json_data.model';
import { CarrusselPhotosService } from './carrussel_photos.service';
import { CarrusselModel } from '../models/carrussel.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';



@Injectable()
export class GlobalVariablesService {
  public deviceType: DeviceType;
  public canChangeParallaxScreenDown: boolean = true;
  public canChangeParallaxScreenUp: boolean = true;
  public doShowArrowDown: boolean = false;
  public showHomeView: boolean = true;
  public loadProjectsView: boolean = false;
  public loadProjectView:boolean = false;
  public loadServicesView: boolean = false;
  public loadContactView: boolean = false;
  public social_instagram: Social[] = undefined;
  public social_facebook: Social[] = undefined;
  public redes: { Facebook: { url: String, page_id: String, token: String }, Instagram: { url: String, user_id: String, token: String } };
  public call_reform_pipe = false;
  public lastSlider: number = 0;;
  public currentView: number = 0;
  public areInProjectView: boolean = false;
  public goingDown: boolean = false;
  public linkedin_url: String = 'https://www.linkedin.com/in/joan-de-salort-amengual-2b8aa110a/';
  public facebook_url: String = 'https://www.facebook.com/juan.desalort/';
  public instagram_url: String = 'https://www.instagram.com/stories/joan_de_salort/';
  public projectData: ProjectData[];
  public slideShowItems: CarrusselModel[] = [];
  public languages = ['es', 'ct', 'en', 'de'];
  public selectedLanguagePos: string;
  public carrousselSpeed: number = 5500;
  public mobileMenuWaiting: boolean = false;

  constructor(
    private translate: TranslateService,

    private deviceService: DeviceDetectorService,
    private carrusellPhotos: CarrusselPhotosService,
    private _peticionesService: PeticionesService, //los servicios suelen ir con '_' delante;
    private _router: Router,
    private location: Location, //https://stackoverflow.com/questions/43009262/change-url-without-redirect-in-angular-2
  ) {


    this.selectedLanguagePos = this.getLanguage();

    if (this.selectedLanguagePos != null) {
      this.translate.use(this.selectedLanguagePos);
    }

  }

  getLanguage() {
    return localStorage.getItem('config/language');
  }

  setLanguages(val: string) {
    localStorage.setItem('config/language', val);
  }





  useLanguage(language: string) {
    this.translate.use(language);
    this.setLanguages(language);
  }




  setCurrentView(newNum: number) {
    this.currentView = newNum;
  }

  setDeviceType() {
    if (this.deviceService.isDesktop() || this.deviceService.isTablet()) {
      this.deviceType = DeviceType.pc;
    } else {
      this.deviceType = DeviceType.mobile;
    }
  }


  setCanChangeParallaxScreenDown(newBool: boolean) {
    this.canChangeParallaxScreenDown = newBool;
  }

  setCanChangeParallaxScreenUp(newBool: boolean) {
    this.canChangeParallaxScreenUp = newBool;
  }

  setDoShowArrowDown(newBool) {
    this.doShowArrowDown = newBool;
  }
  setLoadProjectViewTrue() {
    this.loadProjectView = true;
  }
  setLoadProjectsViewTrue() {
    this.loadProjectsView = true;
  }
  setLoadServicesViewTrue() {
    this.loadServicesView = true;
  }
  setLoadContactViewTrue() {
    this.loadContactView = true;
  }

  setGoingDown(newBool) {
    //console.log('***GOING DOWN?**->' + newBool);
    this.goingDown = newBool;
  }

  async getJsonData() {
    this._peticionesService.getJsonData().subscribe( //con subscribe recogemos la respuesta
      async response => {
        this.projectData = this.shuffleArray(response) as ProjectData[];
        //console.log(this.projectData);
        this.slideShowItems = await this.carrusellPhotos.createCarrussel(this.projectData, this.deviceType == 0);

      },
      error => {
        console.log(<any>error);
      }
    );
  }


  scroll(id: string) {
    if (this.loadProjectView == true || this.loadProjectsView == false) {

      //Si estamos viendo un proyecto particularprimero navegamos a home i después hacemos el scroll a la sección
      this.setLoadProjectsViewTrue();
      if(this.loadProjectView == true)
      {
        this._router.navigateByUrl('/');
      }
      setTimeout(() => {
        this.loadProjectView = false;
        this.scroll(id);
      }, 1000);

    } else {
      this.openMenu();

      const elmnt = document.getElementById(id);
      elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });


    }

  }


    isProjectView(newView: number) {

      this.setUrl(newView);

      this.setCanChangeParallaxScreenUp(false);
      this.setCanChangeParallaxScreenDown(false);
      if (this.areInProjectView == true) {
        this._router.navigateByUrl('/');
        this.setCurrentView(newView);
        this.ifMenuOpenClose();
      }


    }



  isArrowUpVisible(event: any) {
    if (event.visible) {
      this.setCanChangeParallaxScreenUp(false);
      setTimeout(() => {
        this.setCanChangeParallaxScreenUp(true);
      }, 550);
    } else {
      this.setCanChangeParallaxScreenUp(false);
    }
  }


  isHomeVisible(event: any) {
    if (event.visible) {
      this.currentView = 0;
    }
    this.setUrl(this.currentView);
  }


  isSocialVisible(event: any) {
    if (event.visible) {
      this.currentView = 3;
    }
    this.setUrl(this.currentView);
  }


  isFormVisible(event: any) {
    if (event.visible) {
      this.currentView = 4;
    }
    this.setUrl(this.currentView);
  }

  isProjectsVisible(event: any) {
    if (event.visible) {
      this.currentView = 1;
    }
    this.setUrl(this.currentView);
  }

  isServicesVisible(event: any) {
    if (event.visible) {
      this.currentView = 2;
    }
    this.setUrl(this.currentView);
  }

  setUrl(newView: number) {

    switch (newView) {
      case 1:
        this.location.replaceState(this._router.serializeUrl(this._router.createUrlTree(['/proyectos'])));
        break;

      case 2:
        this.location.replaceState(this._router.serializeUrl(this._router.createUrlTree(['/servicios'])));
        break;

      case 3:
        this.location.replaceState(this._router.serializeUrl(this._router.createUrlTree(['/redes'])));
        break;

      case 4:
        this.location.replaceState(this._router.serializeUrl(this._router.createUrlTree(['/contacto'])));
        break;
      default:
        this.location.replaceState(this._router.serializeUrl(this._router.createUrlTree(['/'])));

    }

  }
  isArrowDownVisible(event: any) {
    console.log('Arrow down visble->' + event.visible);
    if (event.visible) {
      this.setCanChangeParallaxScreenDown(false);
      setTimeout(() => {
        this.setCanChangeParallaxScreenDown(true);
      }, 550);
    } else {
      this.setCanChangeParallaxScreenDown(false);
    }
  }
  /*
    checkVisibilityOfView(currentSlideNumber: number) {
      if (this.lastSlider != currentSlideNumber) {
        this.setCanChangeParallaxScreenUp(false);
        this.setCanChangeParallaxScreenDown(false);

        //console.log('currentSlideNumber-> ' + currentSlideNumber);
        //console.log('lastSlider-> ' + this.lastSlider);
        if (currentSlideNumber == 0) {
          this.setCanChangeParallaxScreenUp(true);
          this.setCanChangeParallaxScreenDown(true);
        }
        if (currentSlideNumber == 1) {
          if (this.lastSlider == 0) {
            //We come from up
            this.setCanChangeParallaxScreenUp(true);
          }
          this.setLoadProjectsViewTrue();
        }
        if (currentSlideNumber == 2) {

          this.setLoadServicesViewTrue();
        }
        if (currentSlideNumber == 3) {

          this.setLoadContactViewTrue();
        }
        this.lastSlider = currentSlideNumber;
      }
    }
  */


  is_social_ready() {
    if (//this.social_facebook != undefined &&
      this.social_instagram != undefined && this.social_facebook != undefined) {
      return true;
    } else {
      return false;
    }
  }


  getSocial() {
    ///////////////////////////
    //llamamos redes sociales//
    ///////////////////////////
    this.getlastInstagramPost();
    //this.getlastFacebookPost();
  }


  getlastInstagramPost() {
    this._peticionesService.getlastInstagramPost().pipe(
      finalize(() => {
        setTimeout(() => {
          if (this.social_instagram == undefined) {
            this.social_instagram = [];
          }
        }, 1300);
      })
    ).subscribe( //con subscribe recogemos la respuesta
      response => {

        if (response.error == null) {

          console.log('INSTAGRAM');
          console.log(response);



          let _temp_social: any = [];

          response.data.forEach((item, index) => {

            let tit_text: string;
            let refered_name: string;
            let refered_text: string;
            let img: string;
            let url: string;

            if (item.caption != null) {
              tit_text = item.caption;
            }
            else {
              tit_text = '';
            }
            img = item.media_url;
            url = item.permalink;



            if (tit_text != undefined) {//retwets crec
              var _social = new Social(
                1,
                tit_text,
                refered_name,
                refered_text,
                img,
                url
              );
              _temp_social.push(_social);
            }


            if (index == response.data.length - 1) {
              this.social_instagram = _temp_social;
              //console.log(this.social_instagram);
            }

          });
        } else {
          console.log('Error llamando a Instagram');

        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  /*
    getlastFacebookPost() {
      this._peticionesService.getlastFacebookPost().pipe(
        finalize(() => {
          setTimeout(() => {
            if (this.social_facebook == undefined) {
              this.social_facebook = [];
            }
          }, 1300);
        })
      ).subscribe( //con subscribe recogemos la respuesta
        response => {

          if (response.error == null) {

            console.log('FACEBOOK');
            console.log(response.error);


            let _temp_social: any = [];

            response.data.forEach((item, index) => {
              if (item.message != null) {

                let tit_text: string;
                let refered_name: string;
                let refered_text: string;
                let img: string;
                let url: string;


                tit_text = item.message;
                img = item.full_picture;
                url = item.permalink_url;



                if (tit_text != undefined) {//retwets crec
                  var _social = new Social(
                    2,
                    tit_text,
                    refered_name,
                    refered_text,
                    img,
                    url
                  );
                  _temp_social.push(_social);
                }
              }

              if (index == response.data.length - 1) {
                this.social_facebook = _temp_social;
                console.log(this.social_facebook);
              }


            });

          } else {
            console.log('Error llamando a Facebook');

          }

        },
        error => {
          console.log(<any>error);
        }
      );
    }
  */


  callReformText() {
    //camviam la variable per forçar reform_text
    this.call_reform_pipe = !this.call_reform_pipe;
  }




  openMenu() {
    var el = $('#toggle');
    if (el.hasClass("expanded")) {
      el.removeClass("expanded").addClass("collapsed");
      this.hideMenuMobile();

    }
    else {
      el.removeClass("collapsed").addClass("expanded");
    }
    var elem = $('#menu');
    if (elem.hasClass('move-ltr')) {
      elem.removeClass('move-ltr').addClass('move-rtl');
    } else {
      elem.removeClass('move-rtl').addClass('move-ltr');
    }
  }

  ifMenuOpenClose() {
    if ($('#toggle').hasClass("expanded")) this.openMenu();

  }

hideMenuMobile(){
  if(this.deviceType != 0 && $('#menu_horizontal').is(":visible") && $('#toggle').hasClass("collapsed") ){

    setTimeout(() => {

      $('#menu').removeClass('show_menu').addClass('hide_menu');
       this.mobileMenuWaiting = false;
    }, 9000)

  }

}
showMenuMobile(){
    if(this.deviceType != 0 && this.mobileMenuWaiting == false && $('#menu_horizontal').is(":visible")){
       this.mobileMenuWaiting = true;
      $('#toggle').removeClass("expanded").addClass("collapsed");

      $('#menu').removeClass('hide_menu').addClass('show_menu');

       setTimeout(() => {
          this.hideMenuMobile();

          // stop polling
      }, 1200)

    }

}

  setAreInProjectView(newBool: boolean) {
    this.areInProjectView = newBool;
  }

  shuffleArray(array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }
  getRandomWidth(): number {
    return Math.floor(Math.random() * (5 - 3 + 1)) +3;//+ 3
  }


}
