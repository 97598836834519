/*
https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

implementation of variable --vh

.element-height{
height: 100vh; /* Fallback for browsers that do not support Custom Properties
height: calc(var(--vh, 1vh) * 100);
}
*/

import { Injectable } from '@angular/core';

@Injectable()
export class ViewPortMobileHeight {


  public setCssHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    //document.documentElement.style.setProperty('--vh', `${vh}px`);
    $('html').css('--vh', `${vh}px`);
    //console.log('vh -> ' + vh);
  }
}
