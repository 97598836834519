/////
/**
 *   ng build --base-href='https://becode.app/joan/' --deployUrl='https://becode.app/joan/'
 */  //
///
////

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit, isDevMode, Directive } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Parallax } from './services/parallax.service';
import { WindowRef } from './services/windowref.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalVariablesService } from './services/global.service';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MyHammerConfig } from './../app/components/HammerConfig'


//https://www.npmjs.com/package/ng-lazyload-image
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProjectComponent } from './components/project/project.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { HomeComponent } from './components/home/home.component';

//https://www.npmjs.com/package/ngx-masonry
import { NgxMasonryModule } from 'ngx-masonry';

//https://stackoverflow.com/questions/41302373/angular-2-how-to-detect-if-component-is-currently-in-viewport

import { InViewportModule } from 'ng-in-viewport';


import { LastElementDirective } from './directives/lastelement.directive';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './components/contact/contact.component';


import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ReformTextPipe } from './pipes/reform_text.pipe';

import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { PeticionesService } from './services/peticiones.service';
import { ViewPortMobileHeight } from './services/viewport_mobile.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContactService } from './services/contact.service';
import { CarrusselPhotosService } from './services/carrussel_photos.service';


// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//https://stackoverflow.com/questions/49104958/angular-how-declare-variable-at-module-and-access-it-from-all-component
const versio_i18: string = '00.00.00';




//https://github.com/DevAndyLee/Angular-Hero
//https://github.com/k3nsei/ng-in-viewport
//https://stackblitz.com/edit/angular-5-with-ng-in-viewport?file=app%2Fapp.module.ts
@Directive()
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectComponent,
    ProjectsComponent,
    ServicesComponent,
    ContactComponent,
    ReformTextPipe,
    LastElementDirective,
  ],
  imports: [FormsModule,
    ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule, AppRoutingModule,
    LazyLoadImageModule, NgxMasonryModule, InViewportModule,
    SlickCarouselModule, HttpClientModule, HttpClientJsonpModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    WindowRef, Parallax, DeviceDetectorService, ViewPortMobileHeight,
    GlobalVariablesService, PeticionesService, ContactService, CarrusselPhotosService,
    TranslateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient)//export function HttpLoaderFactory(http: HttpClient,   versio = window['versio']
{
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + versio_i18);
}
