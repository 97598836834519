import { Component, OnInit, ViewChildren, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVariablesService } from 'src/app/services/global.service';
import { InViewportDirective } from 'ng-in-viewport';
import { HttpClient } from '@angular/common/http';
import { Parallax } from './services/parallax.service';
import { TranslateService } from '@ngx-translate/core';


declare var require: any
var $ = require('jquery');




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [GlobalVariablesService, Parallax]
})
export class AppComponent {
  @ViewChildren(InViewportDirective)

  title = 'joanDeSalortServeis';

  constructor(private router: Router,
    public parallax: Parallax,
    public variablesService: GlobalVariablesService,
    public http: HttpClient,
    public translate: TranslateService,
    private _router: Router,

  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    var _systemLanguage: string = navigator.language.substring(0, 2);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(_systemLanguage);


    //Si encara no tenim la vcariable del idioma guardada, la agafam del sistema
    if(variablesService.getLanguage() == null){
    console.log('lang: ' + _systemLanguage);
    if (_systemLanguage == 'es'
      || _systemLanguage == 'en'
      || _systemLanguage == 'ct'
      || _systemLanguage == 'de'


    ) {
      localStorage.setItem('config/language', _systemLanguage);
      this.variablesService.selectedLanguagePos = _systemLanguage;


    } else {
      localStorage.setItem('config/language', 'es');
      this.variablesService.selectedLanguagePos = 'es';

    }
}

    this.variablesService.setDeviceType();
    this.variablesService.getJsonData();
  }

  ngOnInit() {
    this.variablesService.setUrl(0);
    //  this.openMenu();


    //  this.variablesService.setLoadProjectsViewTrue();
  }




  clickLogo() {
    //Check if we're in home, could make it better...
    /*
    if ($(".background").eq(0).hasClass("up-scroll") == true) {
      this.variablesService.openMenu();
    } else {
      this.parallax.slideView(0);
    }
    */
    this.variablesService.openMenu();
  }








}
