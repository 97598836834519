import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from "@angular/core";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides: any = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL },
    'pan': { direction: 6 },
    'pinch': { enable: false },
    'rotate': { enable: false }
  };

}
