
export class Social {
  constructor(
    public Id: number,//0 Twitter // 1 Instagram // 2 Facebook
    public Tit: string,
    public ReferedName: string,
    public ReferedText: string,
    public Img: string,
    public Url: string,
  ) { }
}
